<div
  role="alert"
  [class]="'cbp-border-t-4 cbp-rounded-b cbp-px-4 cbp-py-3 cbp-shadow-md cbcp-alert ' + type"
  [ngClass]="customClass"
>
  <div>
    <ng-container *ngIf="title">
      <p class="cbp-font-bold cbcp-alert-title">{{ title | translate }}</p>
    </ng-container>
    <ng-container *ngIf="subTitle">
      <p class="cbp-text-sm cbcp-alert-subtitle">{{ subTitle | translate }}</p>
    </ng-container>
  </div>
</div>

const DOMAIN = 'api.calenso.io';
const BASE_URL = 'https://' + DOMAIN;

export const environment = {
  apiUrl: BASE_URL + '/api/v1/',
  appointmentConfirmationPageUrl: 'https://dashboard.calenso.io/appointments/',
  avatarImagePath: BASE_URL + '/files/workers/avatar/',
  backgroundImagePath: BASE_URL + '/files/partners/booking_page_background_logo/',
  baseUrl: BASE_URL,
  dashboardUrl: 'https://dashboard.calenso.io',
  defaultBookingPartnerName: 'calenso',
  deployUrl: 'https://book.calenso.io',
  encryptedKey: 'FtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ',
  eventImagePath: BASE_URL + '/files/events/image/',
  eventImgFallback: 'calenso-default-logo.svg',
  faviconImagePath: BASE_URL + '/files/partners/favicon_logo/',
  landingPageImagePath: BASE_URL + '/files/partners/landingpage_logo/',
  partnersImagePath: BASE_URL + '/files/partners/logo/',
  production: false,
  version: '7.6.0',
  webcomponentWidgetUrl: 'https://webcomponent.calenso.io',
};
